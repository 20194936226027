import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/index.less'
import './assets/fonts/iconfont.css'
import 'default-passive-events' // 解决警告
import Common from './assets/common'
import './assets/rem'
import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo)
import VueCookies from 'vue-cookies'//短信过期时间
// import NProgress from 'nprogress'
// import 'nprogress/nprogress.css'
// Vue.use(NProgress);
Vue.use(ElementUI);
Vue.use(VueCookies)

import VueQuillEditor from 'vue-quill-editor'

import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme

Vue.use(VueQuillEditor, /* { default global options } */)

// 全局方法挂载
Vue.prototype.common = Common;
// 全局组件挂载

Vue.config.productionTip = false

Vue.config.silent = true
/* 
  使用的插件
  @riophae/vue-treeselect

 */
  

Vue.filter(
  'ellipsis', function (status) {
    if (!status) return ''
    if (status.length > 70) {
      return status.slice(0,70) + '...'
    }
    return status
    }
)


 new Vue({
            router,
            store,
           
            render: h => h(App),
          //添加到这里,这里的render-event和vue.config.js里面的renderAfterDocumentEvent配置名称一致
            mounted () {
              document.dispatchEvent(new Event('render-event'))
            },

}).$mount('#app')
 


