import { MessageBox } from 'element-ui';


import { ssrCompile } from 'vue-template-compiler';
import router from '../router'
// 存数据
function setItem (key,value) {
  if (typeof value === 'object') {
    value = JSON.stringify(value)
  }
  window.localStorage.setItem(key, value)
}
// 取数据
function getItem (key) {
  const data = window.localStorage.getItem(key)
  try {
    return JSON.parse(data)
  } catch (err) {
    return data
  }
}
// 删除数据
function removeItem (key) {
 window.localStorage.removeItem(key)
}

// 数组查找ID
function arrFindID (id,arr) {
  let index = arr.findIndex(e=> { return e == id})
  if (index == -1) {
    arr.push(id)
  }else {
    arr.splice(index,1)
  }
  return arr
}

// source(value) {
//  
// }
var source = ''
function sourcea (value) {
  this.close()     //http://test.taiyijimu.com http://192.168.1.7:8080/
   source = new EventSource('http://www.taiyijimu.com/' + 'sendMsg?sign='+value)
   source.addEventListener('message', e => {
    // this.source(source.close())
    let ac =  JSON.parse(e.data)
    localStorage.setItem('simga',ac.data)
    if(ac.type == 1 ){
      //跳转注册页面
      localStorage.setItem('userid',ac.data)
      router.push('/compet')     
        this.close()   
    }
    if(ac.type == 5 ){
    
     
      router.push('/compet')     
      this.close()  
    }
    if(ac.type == 2){
     
      MessageBox('已完成注册，无需扫描此注册码', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        router.push({
                  path:'/',
             
                })
      }).catch(() => {
        router.push({
                  path:'/',
             
                }) 
      });
      this.close()
      //已注册可直接登录     
    }
}, false);
 }
 function close (){
   if(source == ''){
     return
   }else{
    source.close()
   }

 }

 

 function codea (val){
  if(val ==401){
    if(   localStorage.getItem('secret') !=undefined){
   
    }else{
      MessageBox('登录状态已过期，请重新登录', '提示', {
        confirmButtonText: '确定',
        type: 'warning'
        }).then(() => {
          localStorage.removeItem('nickname')
         router.push('/')
        
        })
        }
    }
  

}

 
// var httpUrl = 'http://192.168.0.7:8080/center/uploadImg'//see链接地址域名
// var httpUrla = 'http://192.168.0.7:8080/'
var httpUrl = 'http://www.taiyishuju.com/center/uploadImg'//see链接地址域名
 var httpUrla = 'http://www.taiyishuju.com/'//see链接地址域名

export default {
  setItem,
  getItem,
  removeItem,
  arrFindID,
  sourcea,
  httpUrl,
  httpUrla,
  source,
  close,
  codea
}

