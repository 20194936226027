
import Vue from 'vue'
import Vuex from 'vuex'
import common from '../assets/common'

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    token: common.getItem('token'),
    treeList:{},
    menus:[ 
    ],
    all:[],
    shrinkFlag: false,
  },
  mutations: {
    setPrint(state, all) { //设置参数
      state.all = all;
    },
    setToken (state,data) {
      // common.setItem('tokenac',data)
    },
    setTreeList: (state, treeList) => {
      state.treeList = treeList
    },
    setMenus: (state, menus) => {
      state.menus = menus  
    },
    updataShrinkFlag: (state, data) => {
      state.shrinkFlag = data  
    }

  },
  actions: {
  
    logout() {
      // return new Promise (resolve=>{
      //   logout().then(res=>{
      //     localStorage.clear()
      //     router.replace('/login').catch(err=>{})
      //     resolve()
      //   })
      // }).catch(err=>{})
     
       
    }
  },
  modules: {

  }
})
