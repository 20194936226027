import Vue from 'vue'
import VueRouter from 'vue-router'
// import NProgress from 'nprogress'
// import 'nprogress/nprogress.css'
// Vue.use(NProgress)

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '/caca',
    component: () => import('@/views/index/index'),
    meta: {
      title: '智灵瞳',
      content: {
        keywords: '',
        description: '',
        baidu: ''
      },
    }
  },
  {
    path: '/my',
    name: '/my',
    component: () => import('@/views/my/index'),
    meta: {
      title: '智灵瞳',
      content: {
        keywords: '',
        description: '',
        baidu: ''
      },
    }
  },
  {
    path: '/service',
    name: '/service',
    component: () => import('@/views/service/index'),
    meta: {
      title: '智灵瞳',
      content: {
        keywords: '',
        description: '',
        baidu: ''
      },
    }
  },

  {
    path: '/news',
    name: '/news',
    component: () => import('@/views/news/index'),
    meta: {
      title: '智灵瞳',
      content: {
        keywords: '',
        description: '',
        baidu: ''
      },
    }
  },
  {
    path: '/yycj',
    name: '/yycj',
    component: () => import('@/views/yycj/index'),
    meta: {
      title: '智灵瞳',
      content: {
        keywords: '',
        description: '',
        baidu: ''
      },
    }
  },
  {
    path: '/jjfa',
    name: '/jjfa',
    component: () => import('@/views/jjfa/index'),
    meta: {
      title: '智灵瞳',
      content: {
        keywords: '',
        description: '',
        baidu: ''
      },
    }
  },

  {
    path: '/xw',
    name: '/xw',
    component: () => import('@/views/news/xwcontent'),
    meta: {
      title: '',
      content: {
        keywords: '',
        description: '',
        baidu: ''
      },
    }
  },
  {
    path: '/fw',
    name: '/fw',
    component: () => import('@/views/service/fwcon'),
    meta: {
      // title: '智灵瞳-' + sessionStorage.getItem('fw'),

      title: '智灵瞳',
      content: {
        keywords: '',
        description: '',
        baidu: ''
      },
    }
  },
 
  {
    path: '/gzh',
    name: '/gzh',
    component: () => import('@/components/gzh'),
    meta: {
      title: '智灵瞳',
      content: {
        keywords: '',
        description: '',
        baidu: ''
      },
    }
  },
  {
    path: '/login',
    name: '/login',
    component: () => import('@/views/login/index'),
    meta: {
      title: '智灵瞳',
      content: {
        keywords: '',
        description: '',
        baidu: ''
      },
    }
  },
  {
    path: '/up',
    name: '/up',
    component: () => import('@/views/login/up'),
    meta: {
      title: '智灵瞳',
      content: {
        keywords: '',
        description: '',
        baidu: ''
      },
    }
  },

  {
    path: '*',
    component: () => import('@/views/404.vue'),
    // redirect:'/404'
  },
]


// NProgress.configure({     
//   easing: 'ease',  // 动画方式     
//   speed: 1000,  // 递增进度条的速度    
//   showSpinner: false, // 是否显示加载ico    
//   trickleSpeed: 200, // 自动递增间隔    
//   minimum: 0.3 // 初始化时的最小百分比
// })
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
  // mode:'history',
  base: '/',
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
      x: 0,
      y: 0
    }
  }
})
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  // console.log(to, 123123123)
  // let a = 0
  // if (to.fullPath == '/login') { a = 1 }
  // if (to.fullPath !== '/login' && a == 0) {
  //   if (window.localStorage.getItem('token') == null) {
  //     if (to.fullPath == '/up') {
  //       console.log(123);
  //       next('/login')
  //     }
  //   }
  // }
  if (to.meta.content) {
    let head = document.getElementsByTagName('head');
    let meta = document.createElement('meta');
    meta.content = to.meta.content;
    // meta.name['baidu-site-verification'].content['code-cv3SJ1CEpF']

    head[0].appendChild(meta)
  }
  if (to.meta.title) {
    document.title = to.meta.title
  }
  // NProgress.start();


  next()
})

//路由跳转后，页面回到顶部
router.afterEach(() => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  // NProgress.done()
});
// // 消除跳转同一个页面报错
// const originalPush = router.prototype.push;
// router.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch((err) => err);
// };

// router.beforeEach((to, from, next) => {
//   let token = window.localStorage.getItem('token')
//   if (token ) {
//     next()
//   }else {
//     if (to.path == '/login' ) {
//       next()
//     } else {
//       next('/login')
//     }
//   }

// });



export default router
